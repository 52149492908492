<template>
  <Toast />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Cajas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span class="titulo-new"> REPORTE GENERAL DE PAGOS POR QR</span>
    </div>
    <div class="col-12">
      <div class="p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <span><strong>SUCURSAL: </strong></span>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <span><strong>FECHA INCIO: </strong></span>
            <Calendar
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-4">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-info"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="pagos_qrs"
          key="id"
          :value="pagos_qrs"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left" v-if="false">
                <Button
                  v-if="'PagoQr DescargarPDF' in auth.user.permissions"
                  label="PDF Imprimir"
                  icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'"
                  :loading="generandoPDF"
                  :disabled="generandoPDF"
                  @click="generarPDF"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span
              class="flex align-items-center justify-content-center"
              style="
                background-color: #f8d7da;
                padding: 5px;
                border-radius: 5px;
                color: #721c24;
                font-weight: bold;
              "
            >
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column
            class="text-right"
            field="transactionId"
            header="ID TRANSACCION"
          />
          <Column field="paymentDate" header="FECHA PAGO">
            <template #body="{ data }">
              {{ formatDate(data.paymentDate) }}
            </template>
          </Column>
          <Column field="paymentTime" header="HORA PAGO" />
          <Column field="senderBankCode" header="BANCO EMISOR" />
          <Column field="senderName" header="NOMBRE EMISOR" />
          <Column field="senderDocumentId" header="DOCUMENTO EMISOR" />
          <Column field="senderAccount" header="CUENTA EMISOR" />

          <Column
            field="amount"
            header="TOTAL PAGO"
            class="text-right"
            style="font-weight: bold"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.amount) }}
            </template>
          </Column>
          <ColumnGroup type="footer" style="font-weight: bold">
            <Row>
              <Column
                footer="TOTAL PAGOS QR"
                :colspan="7"
                footerStyle="text-align:right ; font-weight: bold"
              />
              <Column class="text-right" :footer="total_qr" />
            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import { useAuth } from "@/stores";
import { sum } from "lodash";

export default {
  data() {
    return {
      fecha_inicio: new Date(),
      enviando: false,
      pagos_qrs: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      generandoPDF: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  ventaService: null,
  sucursalService: null,
  auth: null,
  computed: {
    total_qr() {
      return sum(
        this.pagos_qrs.map((pagos_qr) => {
          return parseFloat(pagos_qr.amount);
        })
      );
    },
  },
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
  },
  methods: {
    generarPDF() {
      if (this.pagos_qrs.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal: this.sucursalSelected,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
        };
        this.ventaService.imprimirPagosQr(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "Se genero el PDF correctamente",
            life: 3000,
          });
        });
      } else
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuscarFiltro() {
      this.pagos_qrs = [];
      this.enviando = true;
      this.cargarPagos();
    },
    cargarPagos() {
      let datos = {
        sucursal: this.sucursalSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
      };
      this.ventaService
        .filtrarPagosQr(datos)
        .then((data) => {
          this.pagos_qrs = data.pagos_qrs || [];
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    formatDate(date) {
      if (date) {
        const [year, month, day] = date.split("T")[0].split("-");
        return `${day}/${month}/${year}`;
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
  border: 1px solid #002236;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
.titulo-new {
  border-bottom: 1px solid #002236;
  font-size: 1.5rem;
  color: #002236;
  font-weight: bold;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
